import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';


function Square({ is_winner_square, value, onSquareClick }) {
  return (
    <button
      className={"square " + (is_winner_square ? "win-box" : "")}
      onClick={onSquareClick}
    >
      {value}
    </button>
  );
}

function Board({ xIsNext, squares, onPlay, onReset }) {
  function handleClick(i) {
    if (calculateWinner(squares) || squares[i]) {
      return;
    }
    const nextSquares = squares.slice();
    if (xIsNext) {
      nextSquares[i] = "X";
    } else {
      nextSquares[i] = "O";
    }
    onPlay(nextSquares);
  }

  const winner = calculateWinner(squares);
  let status;
  if (winner) {
    status = "Winner: " + winner[0];
  } else {
    status = "Next player: " + (xIsNext ? "X" : "O");
  }

  return (
    <>
      <div className="board-head">
        <div className="status">{status}</div>
        <div className="reset" onClick={onReset}><FontAwesomeIcon icon={faRotateRight} /></div>
      </div>
      {Array.from({ length: 3 }, (_, i) => (
        <div className="board-row">
          {Array.from({ length: 3 }, (_, j) => (
            <Square
              is_winner_square={winner ? winner[1].includes(i * 3 + j) : false}
              value={squares[i * 3 + j]}
              onSquareClick={() => handleClick(i * 3 + j)}
            />
          ))}
        </div>
      ))}
    </>
  );
}

export default function Game() {
  const [history, setHistory] = useState([Array(9).fill(null)]);
  const [currentMove, setCurrentMove] = useState(0);
  const [logReverse, setLogReverse] = useState(true);
  const xIsNext = currentMove % 2 === 0;
  const currentSquares = history[currentMove];

  function handlePlay(nextSquares) {
    const nextHistory = [...history.slice(0, currentMove + 1), nextSquares];
    setHistory(nextHistory);
    setCurrentMove(nextHistory.length - 1);
  }

  function jumpTo(nextMove) {
    setCurrentMove(nextMove);
  }

  function findLatestMove(index) {
    if (index < 1 || index > history.length - 1) {
      return null;
    }
    for (let i = 0; i < 9; i++) {
      if (history[index][i] !== history[index - 1][i]) {
        return i;
      }
    }
  }

  function gameReset() {
    setHistory([Array(9).fill(null)]);
    setCurrentMove(0);
  }

  const moves = history.map((squares, move) => {
    let description;
    const latestMove = findLatestMove(move);
    if (move === currentMove) {
      description = `you are at move #${move}`;
    } else if (move === 0) {
      description = "go to start";
    } else {
      description = `Go to move #${move}`;
    }
    return (
      <li key={move}>
        <button onClick={() => jumpTo(move)}>
          {description} | ({Math.floor(latestMove / 3)}, {latestMove % 3})
        </button>
      </li>
    );
  });

  function changeOrder() {
    //code here
    setLogReverse(!logReverse);
  }

  return (
    <div className="game">
      <div className="game-board">
        <Board xIsNext={xIsNext} squares={currentSquares} onPlay={handlePlay} onReset={gameReset} />
      </div>
      <div className="game-info">
        <button onClick={() => changeOrder()}>change order </button>
        <ul>{logReverse ? moves : [...moves].reverse()}</ul>
      </div>
    </div>
  );
}

function calculateWinner(squares) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return [squares[a], lines[i]];
    }
  }
  return null;
}
